import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from "firebase/storage";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithRedirect,
  sendPasswordResetEmail
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import {
  getFunctions,
} from 'firebase/functions';


const firebaseConfig = {
  apiKey: 'AIzaSyCD0viaoiJ92ciy9dbhln8AgDQl7J7Q1HI',
  authDomain: 'smartride-solutions.firebaseapp.com',
  projectId: 'smartride-solutions',
  storageBucket: 'smartride-solutions.appspot.com',
  messagingSenderId: '1071946936241',
  appId: '1:1071946936241:web:6d292813c14a13afccd790',
  measurementId: 'G-R3H32FGRWR'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth()
const firestore = getFirestore(firebaseApp)
// const analytics = getAnalytics(app)
const gProvider = new GoogleAuthProvider()
const storage = getStorage(firebaseApp, "gs://smartride-solutions.appspot.com");
const functions = getFunctions(firebaseApp);

export {
  auth,
  firebaseApp,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  firestore,
  signInWithPopup,
  gProvider,
  createUserWithEmailAndPassword,
  storage,
  functions
}
