class User {
  uid: string
  role: string
  name: string
  email: string
  stripeCustomerId?: string

  constructor ({
    uid,
    role,
    name,
    email,
    stripeCustomerId
  }: {
    uid: string
    role: string
    name: string
    email: string
    stripeCustomerId?: string
  }) {
    this.uid = uid
    this.role = role
    this.name = name
    this.email = email
    this.stripeCustomerId = stripeCustomerId
  }

  static fromMap (json: { [key: string]: any }): User {
    return new User({
      uid: json.uid,
      role: json.role,
      name: json.name,
      email: json.email,
      stripeCustomerId: json.stripeCustomerId
    })
  }

  toMap (): { [key: string]: any } {
    return {
      uid: this.uid,
      role: this.role,
      name: this.name,
      email: this.email,
      stripeCustomerId: this.stripeCustomerId
    }
  }
}

export default User
