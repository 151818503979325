import { RouteRecordRaw } from 'vue-router'

const userRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'Dashboard',
    component: () => import('@/pages/DashboardPage.vue'),
    meta: {
        requiresAuth: true,
        title: 'Dashboard'
    }
  },
  {
    path: '/addresses',
    name: 'Address',
    component: () => import('@/pages/Dashboard/AddressPage.vue'),
    meta: {
        requiresAuth: true,
        title: 'Address'
    }
  },
  {
    path: '/add-address',
    name: 'AddAddress',
    component: () => import('@/pages/Dashboard/ManageAddress.vue'),
    meta: {
        requiresAuth: true,
        title: 'Manage Address'
    }
  },
  {
    path: '/admin/manage-faq',
    name: 'AdminFaq',
    component: () => import('@/pages/Admin/Faq/ManageFaq.vue'),
    meta: {
        requiresAuth: true,
        title: 'Manage FAQ'
    }
  },
  {
    path: '/admin/faqs',
    name: 'AdminFaqs',
    component: () => import('@/pages/Admin/Faq/FaqPage.vue'),
    meta: {
        requiresAuth: true,
        title: 'Faqs'
    }
  },
  {
    path: '/admin/manage-plan',
    name: 'AdminPlan',
    component: () => import('@/pages/Admin/Plan/ManagePlan.vue'),
    meta: {
        requiresAuth: true,
        title: 'Manage Plan'
    }
  },
  {
    path: '/admin/plans',
    name: 'AdminPlans',
    component: () => import('@/pages/Admin/Plan/PlanPage.vue'),
    meta: {
        requiresAuth: true,
        title: 'Plans'
    }
  },

  {
    path: '/admin/manage-car',
    name: 'AdminCar',
    component: () => import('@/pages/Admin/Car/ManageCar.vue'),
    meta: {
        requiresAuth: true,
        title: 'Manage Car'
    }
  },
  {
    path: '/admin/cars',
    name: 'AdminCars',
    component: () => import('@/pages/Admin/Car/CarPage.vue'),
    meta: {
        requiresAuth: true,
        title: 'Car'
    }
  },
]

export default userRoutes
