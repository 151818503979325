import { defineStore } from 'pinia'
import User from '@/models/User'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: {} as User
    }),
    getters: {
        isAuthenticated: (state: any) => !!state.user.uid,
        getUser: (state: any) => state.user
    },
    actions: {
        setUser (user: User) {
            this.user = user
        },
        clearUser () {
            this.user = {} as User
        }
    }
})
