import { createRouter, createWebHistory, Router, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import routes from './routes'
// Nprogress
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { User as FirebaseUser, IdTokenResult}  from 'firebase/auth'
import hasRequiredRoles from '@/utils/role'
import { auth } from '@/services/firebase'
import { getUserData } from '@/lib/user'

const router : Router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(
async  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const user: FirebaseUser | null = auth.currentUser
    let role : string = 'user'
    if (user != null) {
      const idTokenResult: IdTokenResult = await user?.getIdTokenResult()
      role = (idTokenResult.claims.role as string) ?? 'user'
    }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiredRoles = ['requiresAdmin', 'requiresUser']
      .filter(role => to.matched.some(record => record.meta[role]))
      .map(role => role.replace('requires', '').toLowerCase());

    // Start the animation of the route progress bar.
    Nprogress.start()

    // target user now!
    if (requiresAuth && !user) {
      console.log('User not authenticated, redirecting to login')
      next({ name: 'login' })
    } else if (requiresAuth && !hasRequiredRoles([role], requiredRoles)) {
      console.log('User does not have required roles, redirecting to unauthorized')
      next({ name: 'unauthorized' })
    } else if (!requiresAuth && user) {
      console.log('User authenticated, redirecting to dashboard')
      next({ name: 'Dashboard' })
    } else {
      console.log('Proceeding to route')
      next()
    }
  }
)

router.afterEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized
  ) => {
    // Complete the animation of the route progress bar.
    Nprogress.done()
  }
)

export default router
