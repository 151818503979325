import { doc, setDoc, getDoc, serverTimestamp, updateDoc, DocumentReference, DocumentData } from 'firebase/firestore'
import { firestore } from '@/services/firebase'
import { User as FirebaseUser, IdTokenResult } from 'firebase/auth'
import { UserData } from '@/types/user'
import User from '@/models/User'

const updateUserProfile = async (user: FirebaseUser, additionalData = {}) => {
  const request = await fetch('https://api.ipify.org/?format=json')
  const response = await request.json()
  const ip = response.ip

  const userDocRef = doc(firestore, 'users', user.uid)
  const userDoc = await getDoc(userDocRef)

  const userData : UserData = {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    role: 'user',
    ip: ip,
    ...additionalData
  }

  if (!userDoc.exists()) {
    // If the user document doesn't exist, create it with a createdAt timestamp
    userData.createdAt = serverTimestamp()
  }
  // Add or update the lastUpdated field
  userData.lastUpdated = serverTimestamp()

  await setDoc(userDocRef, userData, { merge: true })
}

const getUserProfile = async (uid: string): Promise<UserData | null> => {
  const userRef: DocumentReference<DocumentData> = doc(firestore, 'users', uid)
  const userDoc = await getDoc(userRef)

  if (userDoc.exists()) {
    return userDoc.data() as UserData
  }
  return null
}

const updateLastLogin = async (uid: string): Promise<void> => {
  const userRef: DocumentReference<DocumentData> = doc(firestore, 'users', uid)
  await updateDoc(userRef, {
    lastLogin: serverTimestamp()
  })
}

const getUserData = async (user: FirebaseUser): Promise<User> => {
    // get custom claims.
    const idTokenResult: IdTokenResult = await user.getIdTokenResult()
    // get user role.
    const role: string = (idTokenResult.claims.role as string) ?? 'user'
    const currentUser: FirebaseUser | null = user
    if (!currentUser) {
      throw new Error('No authenticated user found')
    }
    const userProfile: UserData | null = await getUserProfile(currentUser.uid)
    if (!userProfile) {
      throw new Error('User profile not found')
    }
    const email: string = currentUser.email ?? ''
  
    const updatedUser: User = new User({
      uid: currentUser.uid,
      role: role,
      name: userProfile.name ?? '',
      email: email,
      stripeCustomerId: userProfile.stripeCustomerId ?? '',
    })
    return updatedUser
}

export { updateUserProfile, getUserData, updateLastLogin }
