import { RouteRecordRaw } from 'vue-router'

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Auth/LoginPage.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/pages/Auth/SignupPage.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/pages/Auth/ResetPasswordPage.vue')
  }
]

export default authRoutes
