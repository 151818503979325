<template>
  <div v-if="props.loading" class="flex justify-center items-center">
    <div class="loader bg-transparent p-5 rounded-full flex space-x-3">
      <div
        v-for="i in 3"
        :key="i"
        class="w-5 h-5 bg-black dark:bg-gray-300 rounded-full animate-bounce"
        :style="{ animationDelay: `${i * 0.2}s` }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  loading: {
    type: Boolean,
    required: true
  }
});
</script>

<style scoped>
.loader div {
  animation-duration: 0.5s;
}
</style>
