import { createApp, App as VueApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { auth } from '@/services/firebase'

import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/tailwind.css'
import { createPinia } from 'pinia'

let app: VueApp | null = null;

auth.onAuthStateChanged(async (user) => {
    if (!app) {
        app = createApp(App)
        app.use(createPinia()).use(router).mount('#app')
    }
});
