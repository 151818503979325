import { RouteRecordRaw } from 'vue-router'

const siteRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/HomePage.vue')
  },
  {
    path: '/subscriptions',
    name: 'homeSubscriptions',
    component: () => import('@/pages/SubscriptionsPage.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/pages/FaqPage.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('@/pages/SuccessPage.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/pages/UnauthorizedPage.vue')
  },
]

export default siteRoutes
