import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-sans text-text bg-background min-h-screen flex flex-col"
}

import { useAuthStore } from "@/store/auth";
import { auth } from "@/services/firebase";
import User from "@/models/User";
import { getUserData } from "@/lib/user";
import { ref, onMounted, watch, reactive } from "vue";

// @ts-ignore
import LoaderComp from "@/components/LoaderComp.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const authStore = useAuthStore();
const isAuthenticated = authStore.isAuthenticated;
const loggedin = ref(isAuthenticated);
const loading = ref(true);

const fetchUserData = async () => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const updatedUser = await getUserData(currentUser);
    authStore.setUser(updatedUser);
    loggedin.value = true;
  } else {
    loggedin.value = false;
  }
  loading.value = false;
};

onMounted(async () => {
  await fetchUserData();
});

watch(
  () => authStore.user,
  async (newUser: User) => {
    await fetchUserData();
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", null, [
    (!loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(LoaderComp, { loading: loading.value }, null, 8, ["loading"])
    ])
  ]))
}
}

})