import { RouteRecordRaw } from 'vue-router'

import authRoutes from './routes/auth'
import siteRoutes from './routes/site'
import userRoutes from './routes/user'

const routes: Array<RouteRecordRaw> = [
  ...siteRoutes,
  ...authRoutes,
  ...userRoutes,
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/pages/NotFoundPage.vue')
  }
]

export default routes
