<!-- App.vue -->
<template>
  <section>
    <div
      v-if="!loading"
      class="font-sans text-text bg-background min-h-screen flex flex-col"
    >
      <router-view></router-view>
    </div>
    <div>
      <LoaderComp :loading="loading" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/store/auth";
import { auth } from "@/services/firebase";
import User from "@/models/User";
import { getUserData } from "@/lib/user";
import { ref, onMounted, watch, reactive } from "vue";

// @ts-ignore
import LoaderComp from "@/components/LoaderComp.vue";

const authStore = useAuthStore();
const isAuthenticated = authStore.isAuthenticated;
const loggedin = ref(isAuthenticated);
const loading = ref(true);

const fetchUserData = async () => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const updatedUser = await getUserData(currentUser);
    authStore.setUser(updatedUser);
    loggedin.value = true;
  } else {
    loggedin.value = false;
  }
  loading.value = false;
};

onMounted(async () => {
  await fetchUserData();
});

watch(
  () => authStore.user,
  async (newUser: User) => {
    await fetchUserData();
  },
  { deep: true }
);
</script>
