import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_2 = { class: "loader bg-transparent p-5 rounded-full flex space-x-3" }




export default /*@__PURE__*/_defineComponent({
  __name: 'LoaderComp',
  props: {
  loading: {
    type: Boolean,
    required: true
  }
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: "w-5 h-5 bg-black dark:bg-gray-300 rounded-full animate-bounce",
              style: _normalizeStyle({ animationDelay: `${i * 0.2}s` })
            }, null, 4)
          }), 64))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})